import React from 'react';
import { Fade, Modal } from 'react-bootstrap';
import './ModalPopup.scss';

const ModalPopup = (props) => {

  return (
    <Modal
      className={'modal ' + props.className}
      show={props.showModal}
      animation={Fade}
      onHide={() => props.onHide()}
      centered
    >
      <Modal.Header className={props.modalHeader} onHide={props.onHide}>
        {props.modalTitle &&
          <p>{props?.modalTitle}</p>
        }
        {props.closeIcon &&
          <i className="icon icon-x" onClick={() => { props.onClose() }}></i>
        }
      </Modal.Header>
      {props?.children}
    </Modal>
  )
}

ModalPopup.defaultProps = {
  className: 'modal',
}

export default (ModalPopup);