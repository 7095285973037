import { Dropdown } from "react-bootstrap";
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";
import { multilanguage } from "redux-multilanguage";
import Constant from "../../state/utils/constant";
import { showToast } from "../../redux/actions/toastAction";
import ModalPopup from '../../assets/components/UI/ModalPopup/ModalPopup';
import CustomButton from "../../assets/components/UI/CustomButton/CustomButton";
import jwtService from "../../../src/services/jwt.service";
import {authAction} from '../../redux/actions/authAction'
import "./Header.scss";


const Header = (props) => {
    let { strings } = props;
    const [isOpen, setIsOpen] = useState();
    const [showLogoutModal, setShowLogoutModal] = useState(false);
    let history = useNavigate();
    
    const userInfo = props.userInfo;

    const onPressConfirm = (data) => {
        setShowLogoutModal(false);
        props.showToast({
            message: "You have been successfully logged out.",
            type: "success",
        });
        setTimeout(() => {
            history("/signin");
        }, 500);
        props.authAction({})
    };

    return (
        <>
            <div className="header">
                <div className='headerCenter'>
                    <div className='logoarea'>
                        <Link to="/users"><img src={Constant.IMAGESURL.LOGO} className="logo" title="" alt="" /></Link>
                    </div>
                    <div className='profileMain'>
                        <Dropdown onToggle={(isOpen) => setIsOpen(isOpen)}>
                            <Dropdown.Toggle id="dropdown-basic">
                                {isOpen ? <i className='icon-Arrow---Up-2' /> : <i className='icon-Arrow---Down-2' />}
                                <span className="name">{userInfo?.sFirstname} {userInfo?.sLastname}</span>
                                <span className="email">{userInfo?.sEmail}</span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item href="/profile"><i className='icon-Profile' /> {strings['ACCOUNTSETTING']}</Dropdown.Item>
                                <Dropdown.Item href="/changepassword"><i className='icon-Password' /> {strings['CHANGEPASSWORD']}</Dropdown.Item>
                                <Dropdown.Item onClick={() => {
                                    setShowLogoutModal(true);
                                    jwtService.destroyToken()
                                }}><i className='icon-Logout' /> {strings['LOGOUT']}</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <div className="imgArea">
                            {userInfo?.sProfileImage ?
                                <img src={`${process.env.REACT_APP_CLOUDINARY_URL}${userInfo.sProfileImage[0]}`} alt='' />
                                :
                                <span>{userInfo?.sFirstname?.charAt(0) + userInfo?.sLastname?.charAt(0)}</span>
                            }
                        </div>
                    </div>
                </div>
            </div>

            <ModalPopup
                closeIcon={true}
                showModal={showLogoutModal}
                onHide={() => { setShowLogoutModal(false); }}
                onClose={() => {
                    setShowLogoutModal(false)
                }}
                className="logoutModal"
            >
                <h6>{strings['LOGOUT']}</h6>
                <p>{strings['SURELOGOUT']}</p>
                <div className="btnInline">
                    <CustomButton type="button" title={strings["CONFIRM"]} onClick={() => {
                        onPressConfirm(false)
                    }} />
                    <CustomButton type="button" title={strings["CANCEL"]} onClick={() => {
                        setShowLogoutModal(false)
                    }} />
                </div>
            </ModalPopup>

        </>
    );
};

const mapStateToProps = (state) => { return {
    userInfo: state.authReducer.userinfo
}; };
const mapDispatchToProps = { showToast, authAction };
export default connect(mapStateToProps, mapDispatchToProps)(multilanguage(Header));
