const Constant = {
    REGEX: {
        //eslint-disable-next-line
        EMAIL: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        //eslint-disable-next-line
        SPECIALCHARACTERS: /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/,
        UPPERCASE: /.*[A-Z]|[A-Z]/,
        LOWERCASE: /.*[a-z]|[a-z]/,
        NUMBER: /[0-9]/,
        // NAME: /^[a-zA-Z\-\s\u00C0-\u00FF]*$/,
        NAME: /^[a-zA-Z0-9-\-\s]*$/,
        NECNUMBER: /^[0-9]{10}TMP$/,
    },
    IMAGESURL: {
        BANNER: 'https://res.cloudinary.com/daon2n8h6/image/upload/v1698059236/Cloud9/Cloud9-Web/Project-images/d6f047c86018fed4b08914200873cd11.png',
        LOGO: 'https://res.cloudinary.com/daon2n8h6/image/upload/v1698059231/Cloud9/Cloud9-Web/Project-images/1449d60964a552668f7e33e073231ffc.svg',
        LOCKIMG: 'https://res.cloudinary.com/daon2n8h6/image/upload/v1698059236/Cloud9/Cloud9-Web/Project-images/fa29c62de514fe92b6dc5ae948f41cc9.png',
        INFOIMG: 'https://res.cloudinary.com/daon2n8h6/image/upload/v1698059231/Cloud9/Cloud9-Web/Project-images/f06cbe8dc7783fcd0a122043e83ec861.png',
        KEYIMG: 'https://res.cloudinary.com/daon2n8h6/image/upload/v1698059233/Cloud9/Cloud9-Web/Project-images/1c3055f7362a7c8e7d4baac97a1577a6.png',
    }
};
export default Constant;
