import React, { Suspense, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import routes from './routes';
import { multilanguage, loadLanguages } from 'redux-multilanguage';
import { connect } from 'react-redux';
import ToastComponent from './assets/components/UI/ToastComponent/ToastComponent';
import { hideToast } from "../src/redux/actions/toastAction";

function App (props) {
    useEffect(() => {
        props.dispatch(
            loadLanguages({
                languages: {
                    en: require('./translations/english.json')
                }
            })
        );
    });

    window.onbeforeunload = (e) => {
        props.hideToast();
      };

    return (
        <div className="App">
            <Suspense>
            <ToastComponent />
                <BrowserRouter>
                    <Routes>
                        {routes.map((route, index) => {
                            return <Route
                                key={index}
                                exact={route.exact}
                                path={route.path}
                                element={route.component}
                            />;
                        })}
                    </Routes>
                </BrowserRouter>
            </Suspense>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {}
  };
  
  const mapDispatchToProps = {
    hideToast
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(multilanguage(App));