
import loaderReducer from "./loaderReducer";
import toastReducer from "./toastReducer";
import authReducer from './authReducer';
import { combineReducers } from "redux";
import { createMultilanguageReducer } from "redux-multilanguage";

const rootReducer = combineReducers({
  multilanguage: createMultilanguageReducer({ currentLanguageCode: "en" }),
  loading: loaderReducer,
  toastReducer,
  authReducer
});

export default rootReducer;
