import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { LayoutRoute } from './../routes';

import Header from './Header/Header';
import PageNotFound from '../pages/PageNotFound/PageNotFound';

const Layout = (props) => {
    const location = useLocation();
    const [isLogin, setIsLogin] = useState(true);
    useEffect(() => {
        let isLoggedIn = localStorage.getItem('access_token') ? true : false;
        setIsLogin(isLoggedIn);
    }, []);
    const authenticateRoute = () => {
        let { pathname } = location;
        let routeIndex = LayoutRoute.findIndex(route => route.path === pathname);
        // let isLoggedIn = localStorage.getItem('access_token') ? true : false;
        if (pathname !== "/" && routeIndex !== -1) {
            const Component = LayoutRoute[routeIndex].component;
            return isLogin ? Component : <Navigate replace to="/" />;
        } else {
            return <PageNotFound />
        }
    }
    return (
        <div className="main">
            {isLogin && <Header {...props} />}
            <Routes>
                {LayoutRoute.map((route, index) => {
                    return <Route
                        key={index}
                        exact={route.exact}
                        path={route.path}
                        element={authenticateRoute()}
                    />;
                })}
            </Routes>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
    };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
