import React from 'react';
import { multilanguage } from 'redux-multilanguage';
import CustomButton from '../../assets/components/UI/CustomButton/CustomButton';

import './PageNotFound.scss';

const PageNotFound = ({ strings }) => {
    return (
        <div className='pageNotFoundMain'>
            <div className='whiteBox'>
                <h1>Opps!</h1>
                <p>we can’t seem to find the page you’re looking for.</p>
                <h6>Error code : 404</h6>
                <CustomButton
                    type="button"
                    onClick={() => {
                        window.history.back()
                    }}
                    title={'Go back'}

                />
            </div>
        </div>
    );
};

export default multilanguage(PageNotFound);
