import React, { lazy } from 'react';
import Layout from './layout/index';

const SignIn = lazy(() => import(
    /* webpackChunkName: 'signin' */ /* webpackPreload: true */ './pages/Auth/SignIn/SignIn'
));
const ForgotPassword = lazy(() => import(
    /* webpackChunkName: 'forgotpassword' */ /* webpackPreload: true */ './pages/Auth/ForgotPassword/ForgotPassword'
));
const ResetPassword = lazy(() => import(
    /* webpackChunkName: 'resetpassword' */ /* webpackPreload: true */ './pages/Auth/ResetPassword/ResetPassword'
));
const Users = lazy(() => import(
    /* webpackChunkName: 'users' */ /* webpackPreload: true */ './pages/Users/Users'
));
const Profile = lazy(() => import(
    /* webpackChunkName: 'profile' */ /* webpackPreload: true */ './pages/Profile/Profile'
));
const ChangePassword = lazy(() => import(
    /* webpackChunkName: 'changepassword' */ /* webpackPreload: true */ './pages/ChangePassword/ChangePassword'
));

const PageNotFound = lazy(() => import(
    /* webpackChunkName: 'pagenotfound' */ /* webpackPreload: true */ './pages/PageNotFound/PageNotFound'
));

const routes = [
    {
        path: '/',
        component: <SignIn />,
        authenticate: false,
        exact: true
    },
    {
        path: '/signin',
        component: <SignIn />,
        authenticate: false,
        exact: true
    },
    {
        path: '/forgotpassword',
        component: <ForgotPassword />,
        authenticate: false,
        exact: true
    },
    {
        path: '/resetpassword',
        component: <ResetPassword />,
        authenticate: false,
        exact: true
    },
    {
        path: '/*',
        component: <Layout />,
        authenticate: false,
        exact: true
    }
];

export const LayoutRoute = [
    {
        path: '/users',
        component: <Users />,
        exact: true,
        authenticate: true
    },
    {
        path: '/profile',
        component: <Profile />,
        exact: true,
        authenticate: true
    },
    {
        path: '/changepassword',
        component: <ChangePassword />,
        exact: true,
        authenticate: true
    },
    {
        path: '/*',
        component: <PageNotFound />,
        authenticate: true,
        exact: false
    }
];

export default routes;
